<template>
    <div>
                <!-- Form Modal -->
                <b-modal id="modal-society-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="sm" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Receive Truck</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Receive</span>
                    <span v-if="saving">Receiving...</span>
                </b-button>
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group label-for="truck" label="Truck">
                                    <validation-provider #default="{ errors }" name="truck" rules="">
                                        <b-form-input
                                            id="truck"
                                            v-model="queue.truck"
                                            name="truck"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.truck">{{ serverErrors.truck[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group label-for="destination_code" label="Yard">
                                    <validation-provider #default="{ errors }" name="destination_code" rules="">
                                        <b-form-input
                                            id="destination_code"
                                            v-model="queue.destination.code"
                                            name="destination_code"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.destination_code">{{ serverErrors.destination_code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="9">
                                <b-form-group label-for="destination_name" label="Receiving Yard">
                                    <validation-provider #default="{ errors }" name="destination_name" rules="">
                                        <b-form-input
                                            id="destination_name"
                                            v-model="queue.destination.name"
                                            name="destination_name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.destination_name">{{ serverErrors.destination_name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group label-for="source_code" label="From">
                                    <validation-provider #default="{ errors }" name="source_code" rules="">
                                        <b-form-input
                                            id="source_code"
                                            v-model="queue.source.code"
                                            name="source_code"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.source_code">{{ serverErrors.source_code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="9">
                                <b-form-group label-for="source_name" label="From">
                                    <validation-provider #default="{ errors }" name="source_name" rules="">
                                        <b-form-input
                                            id="source_name"
                                            v-model="queue.source.name"
                                            name="source_name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.source_name">{{ serverErrors.source_name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group label-for="document" label="Document #">
                                    <validation-provider #default="{ errors }" name="truck" rules="">
                                        <b-form-input
                                            id="document"
                                            name="document"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :value="queue.tfNumber+'/'+queue.number"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.document">{{ serverErrors.document[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="3">
                                <b-form-group label-for="transporter_code" label="Transporter">
                                    <validation-provider #default="{ errors }" name="transporter_code" rules="">
                                        <b-form-input
                                            id="transporter_code"
                                            name="transporter_code"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            v-model="queue.transporter.code"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.transporter_code">{{ serverErrors.transporter_code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="9">
                                <b-form-group label-for="transporter_name" label="Transporter">
                                    <validation-provider #default="{ errors }" name="transporter_name" rules="">
                                        <b-form-input
                                            id="transporter_name"
                                            name="transporter_name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            v-model="queue.transporter.name"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.transporter_name">{{ serverErrors.transporter_name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group label-for="driver" label="Driver">
                                    <validation-provider #default="{ errors }" name="driver" rules="">
                                        <b-form-input
                                            id="driver"
                                            name="driver"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            v-model="queue.driver"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.driver">{{ serverErrors.driver[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group label-for="license" label="License">
                                    <validation-provider #default="{ errors }" name="license" rules="">
                                        <b-form-input
                                            id="license"
                                            name="license"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            v-model="queue.license"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.license">{{ serverErrors.license[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
                <!-- <b-card>
                    {{ queue }}
                </b-card> -->
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="9" class="mb-md-0 mb-2">
                        <location-filters
                            size="sm"
                            align="horizontal"
                            @filter="changeFilters"
                        />
                    </b-col>

                    <b-col cols="6" md="3">
                        <label>Destination Warehouse</label>
                        <b-form-select size="sm" v-model="warehouse_id">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                                {{ warehouse.code.toUpperCase() }}: {{ warehouse.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table 
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(truck)="data">
                    {{ data.item.transport.truck.plateNumber }}
                </template>

                <template #cell(transport)="data">
                    {{ data.item.transport.tfNumber }}({{ data.item.transport.number }})
                </template>

                <template #cell(driver)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                        <b-avatar
                            size="32"
                            :src="'@/assets/images/avatars/0.jpg'"
                            :text="avatarText(data.item.transport.driver.name)"
                        />
                        </template>
                        <b-link
                            class="font-weight-bold d-block text-nowrap"
                        >
                            {{ data.item.transport.driver.name }}
                        </b-link>
                        <small class="text-muted">Phone: {{ data.item.transport.driver.mobile }}</small>
                    </b-media>
                </template>

                <template #cell(transporter)="data">
                    <span v-if="data.item.transport.transporter.code">{{ data.item.transport.transporter.code.toUpperCase() }}: {{ data.item.transport.transporter.name.toUpperCase() }}</span>
                    <span v-else>{{ data.item.transport.transporter.name.toUpperCase() }}</span>
                </template>

                <template #cell(source)="data">
                    <span v-if="data.item.source.code">{{ data.item.source.code.toUpperCase() }}: {{ data.item.source.name.toUpperCase() }}</span>
                    <span v-else>{{ data.item.source.name.toUpperCase() }}</span>
                </template>

                <template #cell(destination)="data">
                    <span v-if="data.item.destination.code">{{ data.item.destination.code.toUpperCase() }}: {{ data.item.destination.name.toUpperCase() }}</span>
                    <span v-else>{{ data.item.destination.name.toUpperCase() }}</span>
                </template>

                <template #cell(status)="data">
                    <b-alert
                       :variant="data.item.status_color"
                        show
                        class="mb-0"
                    >
                        <div class="alert-body" style="font-size:10px;">
                            {{ data.item.status }}
                        </div>
                    </b-alert>
                </template>

                <template #cell(hours)="data">
                    <b-alert
                       :variant="data.item.status_color"
                        show
                        class="mb-0"
                    >
                        <div class="alert-body" style="font-size:10px;">
                            {{ data.item.hours / 24 >= 1 ? Math.floor(data.item.hours / 24) + ' Days' : data.item.hours + ' Hours' }}
                        </div>
                    </b-alert>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content v-if="!data.item.arrived">
                            <b-badge :variant="'light-'+data.item.status_color">
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </b-badge>
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)" v-if="!data.item.arrived">
                            <feather-icon icon="TruckIcon" />
                            <span class="align-middle ml-50">Receive Truck</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>

                        
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BAlert, BMedia, BAvatar, BLink, BBadge,
} from 'bootstrap-vue'
import LocationFilters from '@core/components/cromis/filters/LocationFilters.vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import queuesStoreModule from '@/views/cromis/queue/queuesStoreModule'
import useQueueList from '@/views/cromis/queue/useQueueList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BAlert, BMedia, BAvatar, BLink, BBadge,
        ValidationObserver, ValidationProvider,
        LocationFilters,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const queue = ref({
            id: null,
            tfNumber: null,
            number: null,
            source: null,
            destination: null,
            truck: null,
            driver: null,
            license: null,
            transporter: null,
            hasArrived: true,
        })

        const warehouses = ref([]);

        const CROMIS_STORE_MODULE_NAME = 'cromis-queue'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, queuesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

        })

        const changeFilters = async (location) => {
            location_id.value = location != null? location.id : null
            warehouse_id.value = null

            await store.dispatch('cromis-queue/warehouses', { location_id: location_id.value })
                        .then(response => {
                            warehouses.value = response.data.warehouses
                        })
                        .catch(error => {
                            console.log('Got error', error)
                        })
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            location_id,
            warehouse_id,
        } = useQueueList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            queue.value = {
                id: item.id,
                tfNumber: item.transport.tfNumber,
                number: item.transport.number,
                source: item.source,
                destination: item.destination,
                truck: item.transport.truck.plateNumber,
                driver: item.transport.driver.name,
                license: item.transport.driver.license,
                transporter: {
                    code: item.transport.transporter.code,
                    name: item.transport.transporter.name,
                },
                'hasArrived': true,
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (queue.value.id === null || queue.value.id === 0)
                handleCreate()
            else
                handleUpdate(queue.value)
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-queue/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to agreement # ${response.data.number} of ${response.data.sale.market.code} sale ${response.data.sale.number} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        return {
            // Data
            queue,
            warehouses,
            warehouse_id,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,

            changeFilters,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleUpdate,
            avatarText,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';
</style>