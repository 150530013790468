import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAgreementsList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'driver', sortable: false },
        { key: 'truck', sortable: false },
        { key: 'transport', label: 'Doc #', sortable: false },        
        { key: 'transporter', sortable: false },
        { key: 'source', label: 'From', sortable: false },
        { key: 'destination', sortable: false },
        { key: 'status', sortable: false },
        { key: 'hours', label: 'Duration', sortable: false },
        'actions',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('number')
    const isSortDirDesc = ref(true)

    const location_id= ref(null)
    const warehouse_id = ref(null)

    const refetch = () => {
        records.value.refresh()
    }

    watch([location_id, warehouse_id, currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store
            .dispatch('cromis-queue/list', {
            location_id: location_id.value,
            warehouse_id: warehouse_id.value,
            query: searchQuery.value,
            per_page: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { queues, meta } = response.data

            meta.value = meta

            callback(queues)
            totalRecords.value = meta.value.total
            from.value = meta.value.from
            to.value = meta.value.to
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching agreements list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveGrowerTypeVariant = society => {
        if (society !== null) return 'success'
        return 'primary'
    }

    const resolveStatusVariant = status => {
        if (status === 1) return 'success'
        if (status === 0) return 'secondary'
        return 'warning'
    }

    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
    
        // resolveUserRoleVariant,
        // resolveUserRoleIcon,
        resolveGrowerTypeVariant,
        resolveStatusVariant,
        refetch,
    
        // Extra Filters
        location_id,
        warehouse_id,
    }
}
